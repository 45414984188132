export const ERROR_MESSAGES = {
  getMaxLengthError: (maxLength: number) =>
    `${maxLength}자 이내로 입력해 주세요.`,
  LOGIN_FAILED: `로그인이 실패하였습니다.\n 다시 시도해주세요.`,
  NO_PERMISSION: '권한이 없습니다.',
  NOT_FOUND: '삭제된 질문입니다.',
  ERROR: '문제가 발생했습니다.\n다시 시도해주시기 바랍니다.',
  PAYLOAD_TOO_LARGE: '업로드 가능한 용량을 초과했습니다.',
  UNSUPPORTED_FILE: '지원하지 않는 파일 형식입니다.',
  SERVICE_AND_TERMS_DENIED:
    '이용약관이 변경되었습니다.\n관리자에게 문의 바랍니다.',
  UPLOAD_FAILED:
    '업로드 중에 문제가 발생했습니다.\n다시 시도해주시기 바랍니다.',
  getWrongFileFormatError: (accept: string) => {
    const acceptList = accept.split(', ').map((type) => {
      return type.replace(/\.([a-zA-Z0-9]+)/g, '$1')
    })

    return `지원하지 않는 파일 형식입니다.\n (지원 형식 : ${acceptList.join(
      ', ',
    )})`
  },
  FILE_LENGTH_EXCEEDED: '음성 파일은 최대 30분까지만\n 텍스트로 변환 됩니다.',
}

export const MESSAGES = {
  BOARDS_ADD_RECOMMEND_TAGS: '선택한 태그가 추가되었습니다.',
  BOARDS_ALREADY_ADD_RECOMMEND_TAGS: '이미 선택된 태그입니다.',
  BOARD_DELETED: '게시글이 삭제되었습니다.',
  BOARD_WLL_BE_DELETED: `삭제하면 더 이상 내용을\n확인하실 수 없습니다.\n삭제하시겠습니까?`,
  BOARD_COMMENT_WILL_BE_UPDATED: `수정하신 답변으로 대체됩니다.\n 답변 작성을 완료하시겠습니까?`,
  EDIT_ALERT_TEXT: `의료팀이 답변을 준비하고 있어\n 질문을 수정할 수 없습니다.`,
  CANCEL: '취소하기',
  BACK: '돌아가기',
  WRITE_CANCEL: '작성중인 내용이 있습니다.\n취소하시겠습니까?',
  UNAUTHENTICATE: `정상적으로 탈퇴 완료되었습니다. 루닛케어는 암 환자, 보호자분들과 언제나 함께 하겠습니다. 감사합니다.`,
  WILL_LOGOUT: '루닛케어에서 로그아웃 하시겠습니까?',
  URL_COPIED:
    '주소가 복사되었습니다. 다른 곳에 붙여넣기 하여 주소를 공유하실 수 있습니다.',
  TAG_REQUIRED: '태그를 선택해 주세요.',
  CANCER_TYPE_REQUIRED: '암 종류를 선택해 주세요.',
  COMMENT_REQUIRED: '답변을 입력해 주세요.',
  CONTENT_REQUIRED: '질문 내용을 입력해 주세요.',
  SAVED: '저장되었습니다.',
  getExcessiveTagSize: (maxCount: number) =>
    `태그는 최대 ${maxCount}개까지 등록할 수 있습니다.`,
  getCopiedNickname: (nickname: string) =>
    `‘${nickname}’ 닉네임이 복사되었습니다.`,
  FAILED_NICKNAME_COPY: '닉네임 복사를 실패했습니다.\n다시 시도해주세요.',
}
